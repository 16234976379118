<template>
  <div class="input-table-reservation" id="form">
    <table>
      <tr>
        <th>{{ $t('common.labelPurposeOfVisit') }}</th>
        <td data-test="reservation-purpose">
          {{ displayReservationPurpose }}
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelDateRequested') }}</th>
        <td data-test="date">
          {{ displayDate + displayStartTime }}
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelName') }}</th>
        <td data-test="owner-name">
          {{
            $t('common.ownerName', {
              first: owner.firstName,
              last: owner.lastName,
              honorific: ''
            })
          }}
        </td>
      </tr>
      <tr>
        <th class="patients-select-area">
          <span class="text">{{ $t('common.labelPetName') }}</span
          ><base-required-mark /><br />
          <span class="note" data-test="max-patients-num">{{
            $t('parts.maximumPatient', { patientNumber: maxPatientsNum })
          }}</span
          ><br />
          <span
            class="err-msg"
            v-if="isValidPatientNum"
            data-test="patient-num-err-msg"
            >{{ $t('parts.errorPatientLimit') }}</span
          >
        </th>
        <td class="patients-select-area">
          <div v-if="patients.length === 0" class="no-patients-message">
            {{ $t('parts.noPatients') }}
          </div>
          <validation-provider class="validate" v-slot="{ errors }">
            <div class="checkbox-area">
              <div
                class="checkbox-wrap"
                v-for="patient in patients"
                :key="patient.id"
              >
                <input
                  class="checkbox"
                  type="checkbox"
                  :id="patient.id"
                  :value="patient.id"
                  :disabled="
                    (!patientAddableFlg && !isChecked(patient.id)) ||
                      !existsSpecies(patient.speciesId)
                  "
                  v-model="reservation.patientsIds"
                  @click="onChange(patient.id)"
                  data-test="patients-checkbox"
                />
                <label
                  class="checkbox-label"
                  :for="patient.id"
                  data-test="patients-name"
                  data-e2e="patients-name"
                >
                  {{ patient.name }}
                </label>
              </div>
            </div>
            <div class="err-msg" data-test="patients-checkbox-err">
              {{ $t(errors[0]) }}
            </div>
          </validation-provider>
          <div v-if="patients.length !== 0" class="patients-add-area-title">
            {{ $t('parts.patientOther') }}
          </div>
          <patient-data-input-form
            v-for="(patient, i) in newPatients"
            :key="patient.key"
            :patient="patient"
            :index="i"
            @del-patient="delPatient"
          />
          <base-button-plus
            class="add-patient-button"
            :text="$t('common.buttonAddPatient')"
            :disabledFlg="!patientAddableFlg"
            @click="addPatient"
          />
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelPhoneNumber') }}</th>
        <td data-test="tel">{{ owner.tel }}</td>
      </tr>
      <tr>
        <th>{{ $t('common.labelEmail') }}</th>
        <td data-test="email">{{ owner.email }}</td>
      </tr>
      <tr>
        <th class="memo-th">
          {{ $t('common.labelRemarks') }}
          <base-required-mark v-if="isMemoRequired" />
          <div class="memo-notice" data-test="memo-notice">
            {{ memoNotice }}
          </div>
        </th>
        <td>
          <validation-provider
            :rules="{ notSurrogatePair: true, requiredRule: isMemoRequired }"
            v-slot="{ errors }"
          >
            <base-multiple-lines-text-box v-model="reservation.memo" />
            <div class="error" data-test="memo-err">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import BaseRequiredMark from '@/components/parts/atoms/BaseRequiredMark.vue'
import PatientDataInputForm from '@/components/parts/molecules/PatientDataInputForm.vue'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus.vue'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox.vue'
import {
  localizeValue,
  formatDate,
  formatStartTime
} from '@/utils/reservation_format'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'InputTableReservation',

  components: {
    BaseRequiredMark,
    PatientDataInputForm,
    BaseButtonPlus,
    BaseMultipleLinesTextBox,
    ValidationProvider
  },

  props: {
    reservation: {
      reservationPurposeId: { type: Number },
      reservationPurposeName: { type: String },
      date: { type: String },
      startTime: { type: String },
      patientsIds: { type: Array },
      memo: { type: String }
    },
    newPatients: { type: Array }
  },

  data() {
    return {
      maxPatientsNum: this.$store.getters[
        'reservationPurpose/getReservationPurposeById'
      ](this.reservation.reservationPurposeId).maxPatients,
      checkedPatientsNum: this.reservation.patientsIds.length
    }
  },

  computed: {
    ...mapGetters({
      owner: 'owner/getOwner',
      patients: 'patient/getPatients',
      species: 'species/getDataIncludeDelData',
      scenario: 'auth/scenario'
    }),
    displayReservationPurpose() {
      const {
        reservationPurposeName,
        reservationPurposeNameEnglish
      } = this.reservation
      return localizeValue(
        reservationPurposeName,
        reservationPurposeNameEnglish,
        this.$i18n.locale
      )
    },
    displayDate() {
      return formatDate(this.reservation.date, this.$i18n.locale)
    },
    displayStartTime() {
      return formatStartTime(this.reservation.startTime, this.$i18n.locale)
    },
    patientsNum() {
      return this.checkedPatientsNum + this.newPatients.length
    },
    patientAddableFlg() {
      return this.maxPatientsNum > this.patientsNum ? true : false
    },
    memoNotice() {
      const { memoNotice, memoNoticeEnglish } = this.$store.getters[
        'reservationSetting/getReservationSetting'
      ]
      return localizeValue(memoNotice, memoNoticeEnglish, this.$i18n.locale)
    },
    isMemoRequired() {
      return (
        this.$store.getters['reservationSetting/getReservationSetting']
          .memoRequiredFlg === 1
      )
    },
    isValidPatientNum() {
      if (this.patientsNum > this.maxPatientsNum) {
        this.$emit('is-valid-patient-num', true)
        return true
      } else {
        this.$emit('is-valid-patient-num', false)
        return false
      }
    }
  },

  watch: {
    patientsNum: {
      handler: function() {
        const flg = this.patientsNum > 0 ? false : true
        this.$emit('exists-patient', flg)
      },
      immediate: true
    }
  },

  created() {
    if (this.reservation.patientsIds.length > this.maxPatientsNum) {
      this.reservation.patientsIds = []
      this.checkedPatientsNum = 0
    }
  },

  mounted() {
    if (
      this.patients.length === 1 &&
      this.newPatients.length === 0 &&
      this.scenario == 'new_reservation'
    ) {
      this.reservation.patientsIds = [this.patients[0].id]
      this.checkedPatientsNum = 1
      this.$emit('check-patient')
    }
  },

  methods: {
    isChecked(id) {
      return this.reservation.patientsIds.includes(id) ? true : false
    },
    onChange(id) {
      this.reservation.patientsIds.includes(id)
        ? _.pull(this.reservation.patientsIds, id)
        : this.reservation.patientsIds.push(id)
      this.checkedPatientsNum = this.reservation.patientsIds.length
      this.$emit('check-patient')
    },
    addPatient() {
      this.$emit('add-new-patient')
    },
    delPatient(obj) {
      this.$emit('del-new-patient', obj)
    },
    existsSpecies(speciesId) {
      return this.species.find(v => v.id === speciesId).delFlg === 0
        ? true
        : false
    }
  }
}
</script>

<style lang="scss" scoped>
.input-table-reservation {
  width: 960px;
  margin: 0 auto;
  > table {
    width: 960px;
    height: 27px;
    @include font-size(18);
    line-height: 1.5;
    text-align: left;
    color: $plus_black;
    border-spacing: 0px 25px;
    > tr {
      > th {
        width: 40%;
        font-weight: bold;
        vertical-align: top;
        > .text {
          margin-right: 10px;
        }
        > .note {
          text-decoration: underline;
          text-decoration-color: #{$plus_red};
        }
        > .err-msg {
          color: #{$plus_red};
        }
        &.memo-th {
          vertical-align: top;
          > .memo-notice {
            white-space: pre-line;
            overflow-wrap: break-word;
            font-weight: normal;
            font-size: 13px;
            word-break: break-all;
          }
        }
      }
      > th.patients-select-area,
      td.patients-select-area {
        padding-top: 10px;
      }
      > td {
        width: 60%;
        @include validate-message();
        vertical-align: top;
        word-break: break-word;
        > span {
          white-space: pre-wrap;
        }
        > .validate {
          > .checkbox-area {
            display: flex;
            flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            > .checkbox-wrap {
              > input[type='checkbox'] {
                display: none;
                &:checked + .checkbox-label:before {
                  opacity: 1;
                  z-index: 1;
                }
                &:checked + .checkbox-label:after {
                  border: 2px solid #{$plus_orange};
                  background-color: #{$plus_orange};
                }
                &:disabled + .checkbox-label:after {
                  background-color: #{$plus_ce_gray};
                  cursor: default;
                }
              }
              > .checkbox-label {
                position: relative;
                padding-left: 32px;
                margin: 0 20px 10px 0;
                display: block;
                cursor: pointer;
                &:after,
                &:before {
                  position: absolute;
                  content: '';
                  display: block;
                  top: 50%;
                }
                &:after {
                  left: 0px;
                  margin-top: -11px;
                  width: 20px;
                  height: 20px;
                  border: 2px solid #{$plus_ce_gray};
                  border-radius: 5px;
                }
                &:before {
                  left: 8px;
                  margin-top: -6px;
                  width: 5px;
                  height: 10px;
                  border-right: 3px solid #{$plus_white};
                  border-bottom: 3px solid #{$plus_white};
                  transform: rotate(45deg);
                  opacity: 0;
                }
              }
            }
          }
        }
        > .no-patients-message {
          padding-top: 15px;
        }
        > .patients-add-area-title {
          font-weight: bold;
          color: $plus_black;
          margin-top: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media (max-width: $global-media-width) {
  .input-table-reservation {
    width: 90%;
    > table {
      width: 100%;
    }
  }
}
@media (max-width: $global-container) {
  .input-table-reservation {
    width: 63%;
    > table {
      width: 100%;
      > tr {
        > th,
        td {
          width: 100%;
          display: block;
          margin-top: 15px;
          > .no-patients-message {
            padding-top: 0px;
          }
        }
      }
    }
  }
  > .checkbox-label {
    padding-left: 29px;
    margin: 5px 7px 0 0;
  }
  > .patients-add-area-title {
    margin-top: -30px;
  }
}
@media (min-width: 640px) {
  .memo-notice {
    width: 90%;
  }
}
</style>
