<template>
  <div class="reservation-input-page">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="page-title">
        <base-page-title>{{
          $t('firstReservationInputPage.inputInformation')
        }}</base-page-title>
      </div>
      <div class="reservation-progress-bar">
        <progress-bar v-bind="progressBarData" />
      </div>
      <input-table-reservation
        :reservation="reservation"
        :newPatients="newPatients"
        @is-valid-patient-num="isValidPatientNum"
        @check-patient="setStore"
        @add-new-patient="addNewPatient"
        @del-new-patient="delNewPatient"
        @exists-patient="changeDisabled"
      />
      <div class="next-button">
        <base-decision-button
          @click="pushToRegistrationConfirm"
          :disabled="invalid || disabledFlg || invalidPatientNum"
          @validate="isValid"
          >{{ $t('common.buttonNext') }}</base-decision-button
        >
      </div>
      <div class="back-button">
        <base-cancel-button @click="backPage">{{
          $t('common.buttonReturn')
        }}</base-cancel-button>
      </div>
      <unsaved-leave-popup @cancel="okLeave" />
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BasePageTitle from '@/components/parts/atoms/BasePageTitle'
import ProgressBar from '@/components/parts/organisms/ProgressBar'
import InputTableReservation from '@/components/parts/organisms/InputTableReservation'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton'
import { mapGetters } from 'vuex'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import _ from 'lodash'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'

export default {
  name: 'ReservationInputPage',

  components: {
    BasePageTitle,
    ProgressBar,
    InputTableReservation,
    BaseDecisionButton,
    BaseCancelButton,
    ValidationObserver,
    UnsavedLeavePopup
  },

  mixins: [ReloadConfirm],

  data() {
    return {
      progressBarData: {},
      disabledFlg: true,
      newPatients: this.$store.getters['patient/getNewPatients'],
      key: 1,
      initialReservation: {},
      alertFlg: false,
      invalidPatientNum: false
    }
  },

  computed: {
    ...mapGetters({
      scenario: 'auth/scenario',
      changeReservation: 'reservation/getChangeReservation',
      reservationPurposes: 'reservationPurpose/getReservationPurposes'
    }),
    newReservation() {
      const newReservation = this.$store.getters[
        'reservation/getNewReservation'
      ]
      if (!newReservation.patientsIds) newReservation.patientsIds = []
      const reservationPurpose = this.reservationPurposes.find(
        v => v.id === newReservation.reservationPurposeId
      )
      newReservation.reservationPurposeName = reservationPurpose.name
      newReservation.reservationPurposeNameEnglish =
        reservationPurpose.nameEnglish
      return newReservation
    },
    reservation() {
      return this.scenario === 'change_reservation'
        ? this.changeReservation
        : this.newReservation
    }
  },

  watch: {
    reservation: {
      handler: function() {
        this.setStore()
      },
      deep: true
    },
    newPatients: function() {
      this.setStore()
    }
  },

  created() {
    this.progressBarData = createProgressBarDataForReservation(this.scenario, 4)
    this.initialReservation = _.cloneDeep(this.reservation)
    this.key = this.newPatients.length
  },

  methods: {
    isValidPatientNum(invalidPatientNum) {
      this.invalidPatientNum = invalidPatientNum
    },
    isValid(invalidFlg) {
      this.$store.dispatch('petorelu/invalidFlg', invalidFlg)
    },
    okLeave() {
      this.$store.dispatch('petorelu/okLeave')
    },
    addNewPatient() {
      this.key += 1
      const patient = { name: '', speciesId: '', breed: '', key: this.key }
      this.newPatients.push(patient)
    },
    delNewPatient(obj) {
      this.newPatients.splice(obj.index, 1)
    },
    setStore() {
      if (this.scenario === 'change_reservation') {
        this.$store.dispatch(
          'reservation/setChangeReservation',
          this.reservation
        )
      } else if (this.scenario === 'new_reservation') {
        this.$store.dispatch('reservation/setNewReservation', this.reservation)
      }
      this.$store.dispatch('patient/setNewPatients', this.newPatients)
    },
    pushToRegistrationConfirm() {
      this.$router.push({ name: 'RegistrationConfirmChangeReservation' })
    },
    backPage() {
      this.$router.back()
    },
    changeDisabled(boolean) {
      this.disabledFlg = boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-input-page {
  > .observer {
    > .page-title {
      margin-top: 70px;
    }
    > .reservation-progress-bar {
      margin: 28px 0 54px;
    }
    > .next-button {
      margin-top: 60px;
    }
    > .back-button {
      margin: 23px 0 80px 0;
    }
  }
}
</style>
